import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import TitleHeader from './TitleHeader'

import './PageHeader.css'


const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = '',
  defaultClass = 'PageHeader',
  home = false
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`${defaultClass} relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="container relative">
        <h1 className="PageHeader--Title">
          {home ? <TitleHeader /> : title}
        </h1>
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
