import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'

//Palete color
//https://coolors.co/00c2bd-faf0ca-f4d35e-ee964b-f95738

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
      home={true}
    />
    <section className="section backgroundOrange">
      <div className="container taCenter">
        <header className="major">
          <h2>Escanea el codigo QR</h2>
        </header>
        <div className="taLeft row">
          <div className="col-3">
            <img className="svg-icon" alt="qr" src="/images/home/qr.svg" />
          </div>
          <div className="col-9">
            <h3>Generación QR</h3>
            Te generamos tu propio código QR con el cual sin necesidad de instalar App te enviara directamente a el menú de tu establecimiento
          </div>
        </div>
      </div>
    </section>

    <section className="section backgroundPrimary">
      <div className="container taCenter">
        <header className="major">
          <h2>Visualizacion</h2>
        </header>
        <div className="taLeft row">
          <div className="col-3">
            <img className="svg-icon" alt="menu" src="/images/home/menu.svg" />
          </div>
          <div className="col-9">
            <h3>Menu digitalizado</h3>
            Tus clientes podrán acceder a el menú de manera individual sin necesidad de una carta.
          </div>
        </div>
      </div>
    </section>

    <section className="section backgroundOrange">
      <div className="container taCenter">
        <header className="major">
          <h2>Ahorro</h2>
        </header>
        <div className="taLeft row">
          <div className="col-3" >
            <img className="svg-icon" alt="euro" src="/images/home/euro.svg"></img>
          </div>
          <div className="col-9" >
            <h3>No mas gastos</h3>
            Ahorra en la compra, impresión de todos tus menús que se rompen y ensucian fácilmente, ahorra tiempo al personal de entrega y recogida de estos mismos.
          </div>
        </div>
      </div>
    </section>

    <section className="section backgroundPrimary" >
      <div className="container taCenter">
        <header className="major">
          <h2>Higiene</h2>
        </header>
        <div className="taLeft row">
          <div className="col-3">
            <img className="svg-icon" alt="higiene" src="/images/home/virus.svg"></img>
          </div>
          <div className="col-9">
            <h3>Antibacterial</h3>
            La utilización de menús digitalizados es la solución más efectiva para evitar la propagación de cualquier tipo de gérmenes y virus como el Covid-19 ya que tus clientes no tendrán contacto físico con tu menú.
          </div>
        </div>
      </div>
    </section>

    {/*
    <section className="section" style={{ backgroundColor: '#F4D35E' }}>
      <div className="container">
        <Content source={body} />
      </div>
    </section>



    <section className="section">

      <div className="container">
        <FeatureHome />
      </div>
    </section> */}

  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta,
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
