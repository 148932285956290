
import React from 'react'
import './TitleHeader.css'

// export default ({ title }) => (
//   <div class="container-title-header">
//     <div class="animate seven">
//       {/* <span>t</span><span>u</span> &nbsp;
//       <span>m</span><span>e</span><span>n</span><span>u</span> &nbsp;
//       <span>d</span><span>i</span><span>g</span><span>i</span><span>t</span><span>a</span><span>l</span> &nbsp;
//       <span>q</span><span>r</span> */}
//     </div>
//   </div>
// )

const TitleHeader = () => {

  return (<div className="container-title-header">
    <div className="animate seven">

      <span>t</span><span>u</span> &nbsp;
    <span>m</span><span>e</span><span>n</span><span>u</span> &nbsp;
    <span>d</span><span>i</span><span>g</span><span>i</span><span>t</span><span>a</span><span>l</span> &nbsp;
    <span>q</span><span>r</span>
    </div>
  </div>);

}

export default TitleHeader;